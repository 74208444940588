import styled from '@emotion/styled';
import { mq } from '~/lib';
import { Link, Text } from '~/shared/components';
import { breakpoints } from '~/theme/breakpoints';
import Button from '~/shared/components/Button';
import Flex from '~/shared/components/Flex';
import { AlignContent } from '../AlignContent';

export const StyledHeading = styled.div(({ theme }) => ({
    marginBottom: theme.space[4],
    [breakpoints.md]: {
        marginBottom: theme.space[6],
    },
}));

export const StyledCategories = styled.div(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.space[3],
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
    },

    [breakpoints.lg]: {
        gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
    },
    [mq(0, 'md')]: {
        margin: `0 ${theme.space[3]}`,
    },
    [mq('md', theme.sizes.contentMaxWidth)]: {
        margin: `0 ${theme.space[5]}`,
    },
}));

export const StyledCategory = styled.div(({ theme }) => ({
    position: 'relative',
    display: 'block',
    flexDirection: 'column',
    alignItems: 'center',
    ':has(:focus-visible)': {
        boxShadow: theme.shadows.accessibility,
    },
    a: {
        ':focus-visible': {
            boxShadow: 'none',
        },
    },

    [breakpoints.md]: {
        [`&:hover ${StyledAction}`]: {
            svg: {
                transform: `translateX(${theme.space[1]})`,
            },
        },
    },
}));

export const StyledBottomGutter = styled.div(({ theme }) => ({
    [breakpoints.xs]: {
        marginBottom: theme.space[3],
    },
    [breakpoints.sm]: {
        marginBottom: theme.space[3],
    },
}));

export const StyledContent = styled.div<{ largePadding?: boolean; noPadding?: boolean }>(
    ({ theme, largePadding, noPadding }) => ({
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',

        position: 'absolute',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        padding: noPadding
            ? 0
            : largePadding
              ? `${theme.space[6]} ${theme.space[6]}`
              : `${theme.space[4]} ${theme.space[4]}`,

        [mq(0, 'sm')]: {
            padding: noPadding
                ? 0
                : largePadding
                  ? `${theme.space[4]} ${theme.space[4]}`
                  : `${theme.space[4]} ${theme.space[4]}`,
        },
    }),
);

export const StyledAction = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSizes['2xs'],
    svg: {
        height: '30px',
        width: '30px',
        paddingLeft: theme.space[1],
        transition: `transform 250ms ${theme.easings.smoothEase}`,
    },
}));

export const StyledLink = styled(Link)(() => ({
    ':hover': {
        textDecoration: 'none',
        color: 'inherit',
    },
}));
export const Gradient = styled.div({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '50%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(1,1,1,0.75) 100%)',
});

export const StyledTextContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    bottom: theme.space[5],
    left: 0,
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
}));

export const StyledActionWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    marginTop: 10,
    gap: 20,
});

export const StyledText = styled(Text)(({ theme }) => ({
    fontSize: `${theme.fontSizes.sm} !important`,
    [mq('lg')]: {
        fontSize: `${theme.fontSizes.lg} !important`,
    },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    [mq(0, 'md')]: {
        display: 'none',
    },

    paddingLeft: '16px',
    paddingRight: '16px',
    svg: {
        height: '16px',
        width: '16px',
    },
    ':hover': {
        backgroundColor: 'transparent',
    },
    '&:disabled': {
        background: 'transparent',
        color: theme.colors.disabled,
    },
}));

export const StyledFlex = styled(Flex)(({ theme }) => ({
    [mq(0, 'md')]: {
        margin: `0 ${theme.space[3]}`,
    },

    [mq('md', theme.sizes.contentMaxWidth)]: {
        margin: `0 ${theme.space[5]}`,
    },
    [mq('md')]: {
        flexDirection: 'row',
        '> *:not(:last-child)': {
            //fix for gap, since gap isn't supported on safari until 14.1
            marginRight: theme.space[2],
            marginTop: 'initial',
        },
    },
}));

export const StyledAlignContent = styled(AlignContent)(() => ({
    height: '100%',
}));
